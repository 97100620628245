import './App.css';
import About from './components/About';
import Carousels from './components/Carousel';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import History from './components/History';
import Services from './components/Services';
import Topbar from './components/Topbar';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  function scrollToDiv(targetId) {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }


  return (
    <div className='res-screen'>
      <div className='screen-render'>
        <Topbar />
        <Header />
        <Carousels />
        <About />
        <Services />
        <History />
        <Contact />
        <Footer scrollToDiv={scrollToDiv} />
      </div>
    </div>
  );
}

export default App;
