import React from "react";
import footerLogo from "../assets/footer-logo.svg";
import { MdCall } from "react-icons/md";
import { IoMail } from "react-icons/io5";
const Footer = ({ scrollToDiv }) => {
  return (
    <div className="footer-container">
      <div className="footer-inside-container">
        <div className="first-footer-part">
          <div className="first-footer-part-mobile">
            <img src={footerLogo} alt="footerLogo" className="footer-logo" />
          </div>
          <p className="footer-text">
            In the ever-evolving and dynamic legal, social, and corporate
            domains, we are well positioned to help our clients with our legal
            expertise, in-depth knowledge, and understanding of a plethora of
            subjects. We are admired by national and international organizations
            for legal advice and litigation.
          </p>
        </div>
        <div className="footer-services-part">
          <div className="heading">Services</div>
          <div className="footer-values" onClick={() => scrollToDiv(1)}>
            Arbitration and Mediation
          </div>
          <div className="footer-values" onClick={() => scrollToDiv(1)}>
            Company and Taxation Law
          </div>
          <div className="footer-values" onClick={() => scrollToDiv(1)}>
            Environmental Law
          </div>
          <div className="footer-values" onClick={() => scrollToDiv(1)}>
            Private International Law
          </div>
          <div className="footer-values" onClick={() => scrollToDiv(1)}>
            Constitutional Law
          </div>
          <div className="footer-values" onClick={() => scrollToDiv(1)}>
            Deeds, Drafting, and Conveyance
          </div>
        </div>
        <div>
          <div className="heading">Contact</div>
          <a href="tel:+919842489823">
            <div className="mail-footer footer-values">
              <MdCall size={14} />
              <div>+91 98424 89823</div>
            </div>
          </a>
          <a href="mailto:reachus@srisailawassociates.com">
            <div className="mail-footer footer-values">
              <IoMail size={14} />
              <div>reachus@srisailawassociates.com</div>
            </div>
          </a>
          {/* <div className="address-footer-start">
            Sri Sai Law Associates,
          </div>
          <div className="footer-values-address">224 NSC Bose Road,</div>
         
          <div className="footer-values-address">
            3rd Floor, National Insurance Building,
          </div>
          <div className="footer-values-address">
            Opposite to the Bar Council of Tamil Nadu and Pondicherry,
          </div>
          <div className="footer-values-address">Chennai, 600001, Tamil Nadu, India </div> */}
        </div>
      </div>
      <div className="border-line"></div>
      <div className="copy-rights-container">
        <div className="copy-rights">© 2024 Copyright, All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Footer;
