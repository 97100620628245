import React from 'react'
import './styles.css'
import Logo from '../assets/header-logo.svg'
const Header = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className='header-container'>
    <div className="container">
      <header className="header">
        <img src={Logo} alt='logo'className='logo-class' style={{cursor:"pointer"}}onClick={scrollToTop}/>
        {/* <button className='contact-us-btn'>
            Contact Us
        </button> */}
      </header>
      <div className="content">
    
      </div>
    </div>
    </div>  
  )
}

export default Header
