import React from "react";
import Parliament from "../assets/parliament.svg";
import Hammer from "../assets/hammer.svg";
const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-heading-mobile">About Sri Sai Law Associates</h1>

      <div className="about-flex">
        <div className="about-content">
          <h2 className="about-heading">About Sri Sai Law Associates</h2>
          <div className="sub-heading-about">
            Sri Sai Law Associates, one among India's top law firms, has been
            built on the decades of excellence.
          </div>
          <p>
            We are a leading law firm providing legal services across India. We
            also partner with leading international law firms abroad for
            international litigations.
          </p>
          <p>
            We are renowned for international and domestic arbitration, company
            and taxation law, environmental law, private international law,
            consitutional, civil law, family law, commercial property law,
            domestic and international crimes, trusts, contracts, wills and
            probate, real estate law, intellectual property rights, patent and
            copyright law, and cyber law.
          </p>
        </div>
        <img src={Parliament} alt="parliament" className="parliament-img" />
      </div>
      <div className="image-container">
        <div className="hammer-relative">
          <div className="dual-image-container">
          <div className="hammer-flex-screen-one">
            <div className="content">
              <div className="d-flex align-items-center gap-4">
                <div className="hammer">
                  <img src={Hammer} alt="Hammer" />
                </div>
                <div className="satisfy-font-text">Why Choose Us</div>
              </div>
              <div className="alpha-sub-heading">
                We are one of the first ethical and efficient law firms in
                India.
              </div>
              <p className="alpha-quotes">
              We empathize with our clients and strive to ensure justice is rendered. We give utmost importance to the welfare of the clients, which drives us to suggest the best possible way to get timely justice for our clients through litigation or alternative dispute resolutions such as arbitration or mediation.
              </p>
              <p className="alpha-quotes">
              We have rich experience in manoeuvring through complex legal problems and our work speaks for itself. When you are in need of legal assistance, reach out to us online or in-person to know how we can help you. We are here to protect your rights and facilitate resolution of your disputes. 

              </p>
            </div>
          </div>
          <div className="hammer-flex-screen-two">
            <div className="content">
              <div className="d-flex align-items-center gap-4">
                <div className="hammer">
                  <img src={Hammer} alt="Hammer" />
                </div>
                <div className="satisfy-font-text">Who We Are</div>
              </div>
              <div className="alpha-sub-heading-2">
                SS Law Associates, one among India's top law firms, has been
                built on the decades of excellence.
              </div>
              <p className="alpha-quotes">
                In the ever-evolving and dynamic legal, social, and corporate
                domains, we are well positioned to help our clients with our
                legal expertise, in-depth knowledge, and understanding of a
                plethora of subjects. We are admired by national and
                international organizations for legal advice and litigation.
              </p>
              <p className="alpha-quotes">
                Our law associates manifest utmost patience and sincerity and
                will handhold the clients through the path to justice. We only
                commit to the cases to which we think a legal solution is
                possible; we do not hesitate to divulge the truth to the clients
                when a solution is not possible.
              </p>
            </div>
          </div>
          </div>
        </div>
        {/* <img src={DualImage} alt="DualImage" className="dual-image" /> */}
        {/* <img
          src={DualImageMobile}
          alt="DualImageMobile"
          className="dual-image-mobile"
        /> */}
      </div>
    </div>
  );
};

export default About;
