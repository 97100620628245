import React from "react";
import CarouselOne from "../assets/carousel-one.svg";
import CarouselTwo from "../assets/carousel-two.svg";
import CarouselThree from "../assets/carousel-three.svg";
import Carousel from "react-bootstrap/Carousel";

const Carousels = () => {
  return (
    <Carousel interval={5000} pause={false} wrap={true}>
      <Carousel.Item>
        <img src={CarouselOne} alt="First slide" />
        <Carousel.Caption className="carousel-captions">
          <h1>"Most ethical law firm in Chennai."</h1>
          <p>
            Ethics, commitment, empathy, and legal acumen manifest in every action we undertake to serve the client.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={CarouselTwo} alt="Second slide" />{" "}
        <Carousel.Caption className="carousel-captions">
          <h1>"Innovative solutions in the pursuit of fairness."</h1>
          <p>
            At Sri Sai law Associates, we believe that the pursuit of fairness is not just
            about following the well-trodden path but about paving new ones.{" "}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={CarouselThree} alt="Third slide" />{" "}
        <Carousel.Caption className="carousel-captions">
          <h1>"Beyond the law, there is humanity."</h1>
          <p>
            "Beyond the law, there is humanity." This is not just a statement;
            it's a promise — a promise to never lose sight of the human element
            in law, to always strive for justice that acknowledges the
            individual.{" "}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Carousels;
