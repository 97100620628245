import React, { useEffect, useState } from "react";
import environmentalLaw from "../assets/environmentsl-law.svg";
import privateInternationalLaw from "../assets/private-international-law.svg";
import constitutionalLaw from "../assets/constitutional-law.svg";
import companyTaxLaw from "../assets/company-tax-law.svg";
import deedsLaw from "../assets/deedsLaw.svg";
import mediationLaw from "../assets/mediation-law.svg";
import Aos from "aos";
import "aos/dist/aos.css";
const Services = () => {
  const [isWebViewport, setIsWebViewport] = useState(false);
  useEffect(() => {
    // Detect viewport width to determine if it's a web view or mobile view
    const handleResize = () => {
      setIsWebViewport(window.innerWidth > 768); // Adjust the threshold as needed
    };
    handleResize(); // Check initial viewport width
    window.addEventListener("resize", handleResize); // Listen for viewport width changes
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, [isWebViewport]);
  useEffect(() => {
    Aos.init({
      duration: 800,
      offset: 50,
      easing: "ease",
      delay: 1000,
    });
  }, []);

  const servicesContent = [
    {
      id: 1,
      title: "Arbitration and Mediation",
      description:
        "Arbitration deals with settling conflicts between firms or individuals through contracts dispute; international arbitration deals with disputes between parties from different countries. We have rich experience in domestic and international arbitration and mediation, saving you time and money.",
      image: mediationLaw,
    },
    {
      id: 2,
      title: "Company and Taxation Law",
      description:
        "Company law governs the rights, relations, and conduct of persons, companies, organisations, and businesses. Commercial and business-related disputes come under the purview of company law.	Taxation law deals with the disputes related to assessment, collection, and enforcement of taxes.        ",
      image: companyTaxLaw,
    },
    {
      id: 3,
      title: "Environmental Law",
      description:
        "The environmental law strives to save the earth and the ecosystem. We advise companies and organisations on the implementation of the environmental law guidelines.",
      image: environmentalLaw,
    },
    {
      id: 4,
      title: "Private International Law",
      description:
        "Private international law, pertains to legal matters involving multiple jurisdictions, necessitating the selection of applicable laws. It addresses cases or transactions with connections to more than one national jurisdiction, guiding decisions on the governing law in cross-border disputes and transactions.",
      image: privateInternationalLaw,
    },
    {
      id: 5,
      title: "Constitutional Law",
      description:
        "	Constitutional law is the paramount legal authority in the Indian legal systems. This covers the laws pertaining to both the government and citizens. Protection of fundamental rights of citizens comes under the purview of the constitutional law.",
      image: constitutionalLaw,
    },
    {
      id: 6,
      title: "Deeds, Drafting, and Conveyance",
      description:
        "	We specialize in registrations and related tasks, ensuring a smooth start for your business ventures. Our legal services extend to crafting airtight legal drafts, clear legal notices, and informed legal opinions, providing comprehensive support for all your legal needs.",
      image: deedsLaw,
    },
  ];
  return (
    <div className="services-container">
      <h1>Our Feature Services</h1>
      <div className="services-text">
        <div className="text-center">
          As one of India’s highly successful law firms, we help our clients
          thrive. For decades, we have built a reputation for our
          expertise, specialised solutions and the ability to think ahead. We
          find the most relevant and highly tailored solutions to our clients’
          composite legal challenges.
        </div>
      </div>
      <div className="cards-container">
        {servicesContent?.map((item, i) => {
          return (
            <div
              className="cards"
              id={item?.id}
              key={i}
              data-aos-offset="200"
              data-aos={
                isWebViewport
                  ? i % 2 === 0
                    ? "fade-right"
                    : "fade-left"
                  : "fade-top"
              }
              data-aos-delay={
                isWebViewport ? (i % 2 === 0 ? "100" : "200") : "100"
              }
            >
              <h3>{item?.title}</h3>
              <div className="card-image-mobile-flex">
                <p className="card-text">{item?.description}</p>
                <img src={item?.image} alt="familyLaw" className="card-image" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
