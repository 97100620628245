import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import founder1 from "../assets/founder-one.svg";
// import founder2 from "../assets/founder-two.svg";
// import founder3 from "../assets/founder-three.svg";
import leftComma from "../assets/comma-left.svg";
import rightComma from "../assets/comma-right.svg";

const History = () => {
  // const [currentIndex, setCurrentIndex] = useState(0);

  // let sliderSettings = {
  //   /* General settings */
  //   // dots: true,
  //   speed: 2500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   centerPadding: "60px",
  //   /* Autoplay settings */
  //   autoplay: true,
  //   infinite: true,
  //   pauseOnHover: true,
  //   autoplaySpeed: 8000,
  //   cssEase: "linear",
  //   /* Responsive settings */
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         // dots: true,
  //       },
  //     },
  //   ],
  //   beforeChange: (oldIndex, newIndex) => {
  //     setCurrentIndex(newIndex);
  //   },
  // };

  // const handleSlideTransition = (index) => {
  //   return index === currentIndex ? 1 : 0;
  // };

  return (
    <div className="history-container">
      <div className="first-history-flex">
        <h2 className="history-heading-mobile">
          The Visionaries Behind Our Firm
        </h2>
        <div className="history-text-container">
        <div className="history-text">
          Together we drive our mission to provide unparalleled legal services
          to our clients. Our vision, expertise, integrity, passion, and
          commitment set the standard for our clients' satisfaction and the
          firm's success.
        </div>
        </div>
        <div className="quote-width">
          <div>
            <div className="left-comma">
              <img src={leftComma} alt="founder1" />
            </div>
            <div className="passion-text">
              Ethics, Empathy, and Honesty are our foundations.
            </div>
            <div className="passion-text">Passion for justice and expertise in law are our pillars.</div>
            <div className="passion-text">Service to society is our driving force.</div>
            <div className="right-comma">
              <img src={rightComma} alt="founder1" className="right-comma" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ height: 0 }}>
          <div className="topRightFiller"></div>
          <div className="bottomLeftFiller"></div>
        </div>
        {/* <Slider {...sliderSettings} className="carousal-container">
          <div
            className="carousal-card"
            style={{ opacity: handleSlideTransition(0) }}
          >
            <img src={founder1} alt="founder1" className="founder-one-image" />
            <div className="founder-details">
              <div className="name">M.M. Pandi, M.A,B.L,</div>
              <div className="position">Founder, Sri Sai Law Associates</div>
            </div>
          </div>
          <div
            className="carousal-card"
            style={{ opacity: handleSlideTransition(2) }}
          >
            <img src={founder3} alt="founder2" />
            <div className="founder-details">
              <div className="name">M.G.B. Jeyakumar, B.Com, M.L,</div>
              <div className="position">Co-Founder, Sri Sai Law Associates</div>
            </div>
          </div>
          <div
            className="carousal-card"
            style={{ opacity: handleSlideTransition(1) }}
          >
            <img src={founder2} alt="founder3" />
            <div className="founder-details">
              <div className="name">P. Karthik, B.E, M.L, PGDIRL, PGDCLCF,</div>
              <div className="position">Co-Founder, Sri Sai Law Associates</div>
            </div>
          </div>
        </Slider> */}
      </div>
    </div>
  );
};

export default History;
