import React from "react";
import { IoMail } from "react-icons/io5";
import { MdCall } from "react-icons/md";

const Topbar = () => {
    
  return (
    <div className="topbar-container">
      <div className=""></div>
        <div className="topbar-flex container">
        <a href="tel:+919842489823">
        <div className="mail-flex">
            <MdCall size={20} />
            <div>+91 98424 89823</div>
          </div>
          </a>
        <a href="mailto:reachus@srisailawassociates.com">
        <div className="mail-flex">
            <IoMail size={20} />
            <div>reachus@srisailawassociates.com</div>
        </div>
          </a>
        </div>
     
    </div>
  );
};

export default Topbar;
