import React, { useState } from "react";
import getInTouch from "../assets/get-in-touch.svg";
import { RiUserSmileLine } from "react-icons/ri";
import { MdOutlineMail } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (event.target.value?.length > 0) {
      setNameError("");
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailValue.trim() === "") {
      setEmailError("");
    } else if (!emailRegex.test(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name === "") {
      setNameError("Please enter your name");
    }
    if (email === "") {
      setEmailError("Please enter your email");
    } else {
      setName("");
      setEmail("");
      setMessage("");
      fetch("/api/emailTrigger", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      })
        .then((response) => {
          console.error("response:", response);
          setName("");
          setEmail("");
          setMessage("");
          toast.success(
            "Your inquiry has been successfully submitted. We'll get back to you shortly."
          );
        })
        .catch((error) => {
          setName("");
          setEmail("");
          setMessage("");
          console.error("Error:", error);
          // toast.error("Error occurred while sending message.");
        });
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-flex">
        <form onSubmit={handleSubmit}>
          <div>
            <h2 className="get-in-touch">Get in Touch!</h2>

            <div className="contact-form-label">Name</div>
            <div
              style={{
                height: 0,
                zIndex: 10,
                position: "relative",
                top: "12px",
                color: "gray",
                left: "10px",
              }}
            >
              <RiUserSmileLine />
            </div>
            <input
              onChange={handleNameChange}
              maxLength={32}
              value={name}
              placeholder="Enter your name"
              style={{ color: "#999", fontSize: "13px" }}
            />
            {nameError && <div className="contact-form-error">{nameError}</div>}

            <div className="contact-form-label">Email</div>
            <div
              style={{
                height: 0,
                zIndex: 10,
                position: "relative",
                top: "12px",
                color: "gray",
                left: "10px",
              }}
            >
              <MdOutlineMail />
            </div>
            <input
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email Id"
              style={{ color: "#999", fontSize: "13px" }}
            />
            {emailError && (
              <div className="contact-form-error">{emailError}</div>
            )}

            <div className="contact-form-label">Case Details</div>
            <textarea
              value={message}
              onChange={handleMessageChange}
              maxLength={255}
              placeholder="Enter a brief description of your case"
              style={{ color: "#999", fontSize: "13px" }}
            />
            <div>
              <button className="contact-us-two-btn submit-btn" type="submit">
                Contact Us
              </button>
            </div>
            <div className="secure">
              Your information is held in strict confidence, and we do not share
              it with any external parties.
            </div>
          </div>
        </form>

        <div>
          <img src={getInTouch} alt="getInTouch" className="get-in-touch-img" />
        </div>
      </div>

      <ToastContainer
        style={{ width: "auto", marginTop: "10px", marginLeft: "20px" }}
      />
    </div>
  );
};

export default Contact;
